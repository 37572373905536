/* colors */
/*@blue:#415f8c;*/
/* PADDING */
/* TOOLBAR */
/*Toolbar colors*/
/*Toolbar button colors*/
/*Toolbar form elements*/
/*Toolbar dropdown menu*/
nav.altai-pagination {
  -webkit-animation: none !important;
  -moz-animation: none !important;
  -o-animation: none !important;
  -ms-animation: none !important;
  animation: none !important;
  display: inline-block;
  margin-top: 0px!important;
}
nav.altai-pagination ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
}
nav.altai-pagination li {
  cursor: pointer;
  display: flex;
  background-color: #F5f5f5;
  margin-right: 5px;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  /*CSS transitions*/
  -o-transition-property: none !important;
  -moz-transition-property: none !important;
  -ms-transition-property: none !important;
  -webkit-transition-property: none !important;
  transition-property: none !important;
  /*CSS transforms*/
  -o-transform: none !important;
  -moz-transform: none !important;
  -ms-transform: none !important;
  -webkit-transform: none !important;
  transform: none !important;
  /*CSS animations*/
  -webkit-animation: none !important;
  -moz-animation: none !important;
  -o-animation: none !important;
  -ms-animation: none !important;
  animation: none !important;
}
nav.altai-pagination li.active {
  background-color: #ECECEC;
}
.altai-pagination-page-count {
  display: inline-block;
  float: right;
}
